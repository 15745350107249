<template>
  <section>
    <div class="mx-auto mt-5 text-center">
      <v-form ref="form" v-model="valid" lazy-validation class="d-flex flex-column mb-4" @submit.prevent="validate">
        <v-text-field 
          class="w-100 border" 
          solo 
          flat 
          :label="$t('Search...')" 
          prepend-inner-icon="mdi-magnify"
          color="primary"
          v-model="searchText" 
          @keyup.enter="validate" 
          hide-details 
          :rules="[(v) => !!v]" 
          required>
        </v-text-field>
      </v-form>
      


      <span
        class="f12 font-weight-bold"
        v-if="
          Doctors.length == 0 &&
          Videos.length == 0 &&
          Podcasts.length == 0 &&
          Posts.length == 0 && 
          notFindShow == false"
        >
        {{ $t("Search through questions, podcasts, content, specialists") }}
      </span>
      <span
        class="f16 font-weight-bold" v-else-if="notFindShow">
        {{ $t("No results found") }}
        </span>
    </div>
    <v-row v-if="Doctors.length > 0">
      <span class="f16 font-weight-bold mt-4 mb-2">{{ Doctors.length }} {{ $t("cases in specialists") }}</span>
      <v-col class="" v-for="(item, index) in Doctors" :key="index" cols="12">
        <v-card class="d-flex align-center py-2 border rounded-lg" elevation="0" :to="`/c/specialist/${item.id}`">
           <v-avatar size="96" rounded class="ms-3">
             <v-img :lazy-src="require('@/assets/images/profile-circle.png')" :src="item.pic != null ? item.pic : require('@/assets/images/profile.jpg')"></v-img>
           </v-avatar>
           <span class="f14 font-weight-bold">{{ item.firstname }} {{ item.lastname }}</span>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="Posts.length > 0">
      <span class="f16 font-weight-bold mt-4 mb-2">{{ Posts.length }} {{ $t("cases in contents") }}</span>
      <v-col class="" v-for="(item, index) in Posts" :key="index" cols="12">
        <v-card class="d-flex align-center py-2 border rounded-lg" elevation="0" :to="`/c/contents/post/${item.id}`">
          <v-avatar  size="96" rounded class="ms-3">
            <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="item.picture != null ? item.picture : require('@/assets/images/placeholder.png')"></v-img>
          </v-avatar>
          <v-card flat class="d-flex flex-column">
            <div class="max-2-line">
              <span class="f14 font-weight-bold"> {{ item.title }}</span>
            </div>
            <span class="f12 font-weight-bold text-blue">{{
              item.publisherName
            }}</span>
            <span class="f11 font-weight-light align-items-center d-flex grey--text">
              <i class="fal fa-clock me-1 ms-0"></i>
              {{ item.createDate | moment("from", "now") }}
            </span>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="Videos.length > 0">
      <span class="f16 font-weight-bold mt-4 mb-2">{{ Videos.length }} {{ $t("cases in videos") }}</span>
      <v-col class="" v-for="(item, index) in Videos" :key="index" cols="12">
        <v-card class="d-flex align-center py-2 border rounded-lg" elevation="0" :to="`/c/contents/video/${item.id}`">
          <v-avatar  size="96" rounded class="ms-3">
            <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="item.picture != null ? item.picture : require('@/assets/images/placeholder.png')"></v-img>
          </v-avatar>
          <v-card flat class="d-flex flex-column">
            <div class="max-2-line">
              <div class="max-2-line">
                <span class="f14 font-weight-bold"> {{ item.title }}</span>
              </div>
            </div>
            <span class="f12 font-weight-bold text-blue">{{
              item.publisherName
            }}</span>
            <span class="f11 font-weight-light align-items-center d-flex grey--text">
              <i class="fal fa-clock me-1 ms-0"></i>
              {{ item.createDate | moment("from", "now") }}
            </span>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="Podcasts.length > 0">
      <span class="f16 font-weight-bold mt-4 mb-2">{{ Podcasts.length }} {{ $t("cases in podcasts") }}</span>
      <v-col class="" v-for="(item, index) in Podcasts" :key="index" cols="12">
        <v-card class="d-flex align-center py-2 border rounded-lg" elevation="0" :to="`/c/contents/podcast/${item.id}`">
          <v-avatar  size="96" rounded class="ms-3">
            <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="item.picture != null ? item.picture : require('@/assets/images/placeholder.png')"></v-img>
          </v-avatar>
          <v-card flat class="d-flex flex-column">
            <div class="max-2-line">
              <span class="f14 font-weight-bold "> {{ item.title }}</span>
            </div>
            <span class="f12 font-weight-bold text-blue">{{
              item.publisherName
            }}</span>
            <span class="f11 font-weight-light align-items-center d-flex grey--text">
              <i class="fal fa-clock me-1 ms-0"></i>
              {{ item.createDate | moment("from", "now") }}
            </span>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import ApiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();
export default {
  data() {
    return {
      searchText: null,
      submit_loading: false,
      valid: false,

      notFindShow: false,
      Doctors: [],
      Videos: [],
      Posts: [],
      Podcasts: [],
    };
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.submitForm();
      }
    },
    submitForm() {
        this.submit_loading = true;
        ApiService.searchContentService(this.searchText)
        .then((response) => {
          this.submit_loading = false;
          this.Doctors = response.data.doctors;
          this.Videos = response.data.videos;
          this.Posts = response.data.posts;
          this.Podcasts = response.data.podcasts;
          response.data.doctors.length == 0 &&
          response.data.videos.length == 0 &&
          response.data.posts.length == 0 &&
          response.data.podcasts.length == 0
            ? (this.notFindShow = true)
            : (this.notFindShow = false);
        })
        .catch((err) => {
          this.submit_loading = false;
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          });
        });
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
  },
};
</script>