var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "mx-auto mt-5 text-center" },
        [
          _c(
            "v-form",
            {
              ref: "form",
              staticClass: "d-flex flex-column mb-4",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.validate.apply(null, arguments)
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c("v-text-field", {
                staticClass: "w-100 border",
                attrs: {
                  solo: "",
                  flat: "",
                  label: _vm.$t("Search..."),
                  "prepend-inner-icon": "mdi-magnify",
                  color: "primary",
                  "hide-details": "",
                  rules: [(v) => !!v],
                  required: "",
                },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.validate.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.searchText,
                  callback: function ($$v) {
                    _vm.searchText = $$v
                  },
                  expression: "searchText",
                },
              }),
            ],
            1
          ),
          _vm.Doctors.length == 0 &&
          _vm.Videos.length == 0 &&
          _vm.Podcasts.length == 0 &&
          _vm.Posts.length == 0 &&
          _vm.notFindShow == false
            ? _c("span", { staticClass: "f12 font-weight-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "Search through questions, podcasts, content, specialists"
                      )
                    ) +
                    " "
                ),
              ])
            : _vm.notFindShow
            ? _c("span", { staticClass: "f16 font-weight-bold" }, [
                _vm._v(" " + _vm._s(_vm.$t("No results found")) + " "),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.Doctors.length > 0
        ? _c(
            "v-row",
            [
              _c("span", { staticClass: "f16 font-weight-bold mt-4 mb-2" }, [
                _vm._v(
                  _vm._s(_vm.Doctors.length) +
                    " " +
                    _vm._s(_vm.$t("cases in specialists"))
                ),
              ]),
              _vm._l(_vm.Doctors, function (item, index) {
                return _c(
                  "v-col",
                  { key: index, attrs: { cols: "12" } },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass:
                          "d-flex align-center py-2 border rounded-lg",
                        attrs: {
                          elevation: "0",
                          to: `/c/specialist/${item.id}`,
                        },
                      },
                      [
                        _c(
                          "v-avatar",
                          {
                            staticClass: "ms-3",
                            attrs: { size: "96", rounded: "" },
                          },
                          [
                            _c("v-img", {
                              attrs: {
                                "lazy-src": require("@/assets/images/profile-circle.png"),
                                src:
                                  item.pic != null
                                    ? item.pic
                                    : require("@/assets/images/profile.jpg"),
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "f14 font-weight-bold" }, [
                          _vm._v(
                            _vm._s(item.firstname) + " " + _vm._s(item.lastname)
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.Posts.length > 0
        ? _c(
            "v-row",
            [
              _c("span", { staticClass: "f16 font-weight-bold mt-4 mb-2" }, [
                _vm._v(
                  _vm._s(_vm.Posts.length) +
                    " " +
                    _vm._s(_vm.$t("cases in contents"))
                ),
              ]),
              _vm._l(_vm.Posts, function (item, index) {
                return _c(
                  "v-col",
                  { key: index, attrs: { cols: "12" } },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass:
                          "d-flex align-center py-2 border rounded-lg",
                        attrs: {
                          elevation: "0",
                          to: `/c/contents/post/${item.id}`,
                        },
                      },
                      [
                        _c(
                          "v-avatar",
                          {
                            staticClass: "ms-3",
                            attrs: { size: "96", rounded: "" },
                          },
                          [
                            _c("v-img", {
                              attrs: {
                                "lazy-src": require("@/assets/images/placeholder.png"),
                                src:
                                  item.picture != null
                                    ? item.picture
                                    : require("@/assets/images/placeholder.png"),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-card",
                          {
                            staticClass: "d-flex flex-column",
                            attrs: { flat: "" },
                          },
                          [
                            _c("div", { staticClass: "max-2-line" }, [
                              _c(
                                "span",
                                { staticClass: "f14 font-weight-bold" },
                                [_vm._v(" " + _vm._s(item.title))]
                              ),
                            ]),
                            _c(
                              "span",
                              { staticClass: "f12 font-weight-bold text-blue" },
                              [_vm._v(_vm._s(item.publisherName))]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "f11 font-weight-light align-items-center d-flex grey--text",
                              },
                              [
                                _c("i", {
                                  staticClass: "fal fa-clock me-1 ms-0",
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("moment")(
                                        item.createDate,
                                        "from",
                                        "now"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.Videos.length > 0
        ? _c(
            "v-row",
            [
              _c("span", { staticClass: "f16 font-weight-bold mt-4 mb-2" }, [
                _vm._v(
                  _vm._s(_vm.Videos.length) +
                    " " +
                    _vm._s(_vm.$t("cases in videos"))
                ),
              ]),
              _vm._l(_vm.Videos, function (item, index) {
                return _c(
                  "v-col",
                  { key: index, attrs: { cols: "12" } },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass:
                          "d-flex align-center py-2 border rounded-lg",
                        attrs: {
                          elevation: "0",
                          to: `/c/contents/video/${item.id}`,
                        },
                      },
                      [
                        _c(
                          "v-avatar",
                          {
                            staticClass: "ms-3",
                            attrs: { size: "96", rounded: "" },
                          },
                          [
                            _c("v-img", {
                              attrs: {
                                "lazy-src": require("@/assets/images/placeholder.png"),
                                src:
                                  item.picture != null
                                    ? item.picture
                                    : require("@/assets/images/placeholder.png"),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-card",
                          {
                            staticClass: "d-flex flex-column",
                            attrs: { flat: "" },
                          },
                          [
                            _c("div", { staticClass: "max-2-line" }, [
                              _c("div", { staticClass: "max-2-line" }, [
                                _c(
                                  "span",
                                  { staticClass: "f14 font-weight-bold" },
                                  [_vm._v(" " + _vm._s(item.title))]
                                ),
                              ]),
                            ]),
                            _c(
                              "span",
                              { staticClass: "f12 font-weight-bold text-blue" },
                              [_vm._v(_vm._s(item.publisherName))]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "f11 font-weight-light align-items-center d-flex grey--text",
                              },
                              [
                                _c("i", {
                                  staticClass: "fal fa-clock me-1 ms-0",
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("moment")(
                                        item.createDate,
                                        "from",
                                        "now"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.Podcasts.length > 0
        ? _c(
            "v-row",
            [
              _c("span", { staticClass: "f16 font-weight-bold mt-4 mb-2" }, [
                _vm._v(
                  _vm._s(_vm.Podcasts.length) +
                    " " +
                    _vm._s(_vm.$t("cases in podcasts"))
                ),
              ]),
              _vm._l(_vm.Podcasts, function (item, index) {
                return _c(
                  "v-col",
                  { key: index, attrs: { cols: "12" } },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass:
                          "d-flex align-center py-2 border rounded-lg",
                        attrs: {
                          elevation: "0",
                          to: `/c/contents/podcast/${item.id}`,
                        },
                      },
                      [
                        _c(
                          "v-avatar",
                          {
                            staticClass: "ms-3",
                            attrs: { size: "96", rounded: "" },
                          },
                          [
                            _c("v-img", {
                              attrs: {
                                "lazy-src": require("@/assets/images/placeholder.png"),
                                src:
                                  item.picture != null
                                    ? item.picture
                                    : require("@/assets/images/placeholder.png"),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-card",
                          {
                            staticClass: "d-flex flex-column",
                            attrs: { flat: "" },
                          },
                          [
                            _c("div", { staticClass: "max-2-line" }, [
                              _c(
                                "span",
                                { staticClass: "f14 font-weight-bold" },
                                [_vm._v(" " + _vm._s(item.title))]
                              ),
                            ]),
                            _c(
                              "span",
                              { staticClass: "f12 font-weight-bold text-blue" },
                              [_vm._v(_vm._s(item.publisherName))]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "f11 font-weight-light align-items-center d-flex grey--text",
                              },
                              [
                                _c("i", {
                                  staticClass: "fal fa-clock me-1 ms-0",
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("moment")(
                                        item.createDate,
                                        "from",
                                        "now"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }